import React, { useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Column,
  Table,
  SortDirection,
  AutoSizer,
  WindowScroller,
} from "react-virtualized";
import _ from "lodash";
import { Checkbox, Button, Icon, List, Popup, Menu } from "semantic-ui-react";
import "react-virtualized/styles.css"; // only needs to be imported once
import {
  markAttendanceLearners,
  markCourseCompletionLearners,
} from "../../Webservices/genericRestApis";
import {
  getLMSEnrollments,
  selectUser,
} from "../../Redux/Actions/enrollmentsAction";
import axios from "axios";
import "./../../config";
import LoadSpinner from "../../components/LoadSpinner";
import "./user.css";
import "./schooxRosterTable.css";

export default function SchooxRosterTable({}) {
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("ASC");
  const { enrollments: currentEnrollments } = useSelector(
    (state) => state.enrollments
  );
  const { course: currentCourse } = useSelector((state) => state.courses);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [enrollments, setEnrollments] = useState([]);
  const [popupStatusMsg, setPopupStatusMsg] = useState("");
  const [sortedList, setSortedList] = useState([]);
  const [checked, setChecked] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  //let baseApiUrl = "http://rlddocebo.localhost:8080/v1";
  let baseApiUrl = global.config.baseApiUrl;

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoaded(true);

    console.log("Enrollments: " + JSON.stringify(currentEnrollments));

    if (currentEnrollments && currentEnrollments.data) {
      setEnrollments(currentEnrollments.data.enrollments);
      setSortedList(currentEnrollments.data.enrollments);
    }else if(currentEnrollments){      
      setEnrollments(currentEnrollments);
      setSortedList(currentEnrollments);
    }
  }, [currentEnrollments]);

  // eslint-disable-next-line no-shadow
  function _sortList({ sortBy, sortDirection }) {
    const newList = _.sortBy(enrollments, [sortBy]);
    if (sortDirection === SortDirection.DESC) {
      newList.reverse();
    }
    return newList;
  }

  // eslint-disable-next-line no-shadow
  function _sort({ sortBy, sortDirection }) {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setSortedList(_sortList({ sortBy, sortDirection }));
  }

  /*async function performAttendance(e) {
    setIsLoaded(false);
    console.log('performAttendance buttonClick: ' + JSON.stringify(e));
    let learnersArray = [];
    var failed = false;
    console.log("performAttendance:Learners:checked: " + JSON.stringify(checked));
    
    if (checked.length>0) {
      learnersArray = [...checked];
    } else {
      learnersArray.push(e.id);
    }
    console.log("performAttendance:Learners:learnersArray " + JSON.stringify(learnersArray));

    let reqBody = {"courseId": currentCourse.course_id,
                     "sessionId": currentSession.session_id,
                     "eventId": currentEvent.event_id,
                     "accessToken": currentUser.accessToken,
                     "cancel_checkin": "",
                     "mark_attendance": "true",
                     "mark_completion": "",
                     "learners": learnersArray};
    console.log('performAttendance: ' + JSON.stringify(reqBody));
    let response = await markAttendanceLearners(reqBody);
    console.log('performAttendance response: ' + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
    }
    setIsLoaded(true);
  }*/

  async function performCompletion(e) {
    setIsLoaded(false);
    console.log("performCompletion buttonClick: " + JSON.stringify(e));
    let learnersArray = [];
    var failed = false;
    console.log(
      "performCompletion:Learners:checked " + JSON.stringify(checked)
    );

    if (checked.length > 0) {
      learnersArray = [...checked];
    } else {
      learnersArray.push(e.id);
    }
    console.log(
      "performCompletion:Learners:learnersArray " +
        JSON.stringify(learnersArray)
    );

    let reqBody = {
      courseId: currentCourse.course_id,
      accessToken: currentUser.accessToken,
      cancel_checkin: "",
      mark_attendance: "",
      mark_completion: "true",
      learners: learnersArray,
      jwt: currentUser.jwt,
      completionDate: convertDateTBL(new Date()),
    };
    console.log("performCompletion: " + JSON.stringify(reqBody));
    let response = await markCourseCompletionLearners(reqBody);
    console.log("performCompletion response: " + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
    }
    setIsLoaded(true);
  }

  function _cellRendererButtonWalkIn(dataKey, rowIndex) {
    return (
      <>
        <div index={rowIndex} role="gridcell">
          {sortedList[rowIndex][dataKey] === "1" ? (
            <Icon name="male" size="large" />
          ) : (
            <Icon name="registered" size="large" />
          )}
        </div>
      </>
    );
  }

  /*function _cellRendererButtonAttended(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
        >
          {sortedList[rowIndex][dataKey] === '1' ?
                        <Icon color='green' name='checkmark' size='large' />
                        : <Button primary onClick={() => performAttendance(sortedList[rowIndex])}>Mark Attended</Button>}
        </div>
      </>
    );
  }*/

  function _cellRendererButtonCompleted(dataKey, rowIndex) {
    return (
      <>
        <div index={rowIndex} role="gridcell">
          {sortedList[rowIndex][dataKey] === "1" ? (
            <Popup
              content={sortedList[rowIndex].completiondate.slice(0, 10)}
              trigger={<Icon color="green" name="checkmark" size="large" />}
            />
          ) : (
            <Button
              primary
              onClick={() => performCompletion(sortedList[rowIndex])}
            >
              Mark Complete
            </Button>
          )}
        </div>
      </>
    );
  }

  function _cellRendererButtonCompletionDate(dataKey, rowIndex) {
    return (
      <>
        <div index={rowIndex} role="gridcell">
          {sortedList[rowIndex].completiondate.slice(0, 10)}
        </div>
      </>
    );
  }

  function _cellRendererButtonPreviousCompletions(dataKey, rowIndex) {
    return (
      <>
        <div index={rowIndex} role="gridcell">
          {sortedList[rowIndex].previouscompletiondates}
        </div>
      </>
    );
  }

  function _cellRendererButtonCertificate(dataKey, rowIndex) {
    return (
      <>
        <div index={rowIndex} role="gridcell">
          {sortedList[rowIndex][dataKey] != "" ? (
            <Menu.Item
              href={sortedList[rowIndex].completioncertificate}
              position="right"
              target="_blank"
            >
              <Icon name="file" size="big" />
            </Menu.Item>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  function _cellRenderer(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
          style={dataKey === "status" ? { color: "green" } : {}}
        >
          {sortedList[rowIndex][dataKey]}
        </div>
      </>
    );
  }

  const _onChangeHeaderCheckbox = (data) => {
    data.checked ? setChecked(sortedList.map((row) => row.id)) : setChecked([]);
  };

  const convertDateTBL = (date) => {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split("");
    var ddChars = dd.split("");

    var cmplDt = yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0])
    console.log("DATE VALUE TO BE SET IN markComplete: Inside schooxRosterTable:convertDateTBL: " + JSON.stringify(cmplDt));

    return (
      yyyy +
      "-" +
      (mmChars[1] ? mm : "0" + mmChars[0]) +
      "-" +
      (ddChars[1] ? dd : "0" + ddChars[0])
    );
  };

  const _onChangeRowCheckbox = (data) => {
    const newRow = sortedList[data.index].id;
    console.log("newRow: " + JSON.stringify(sortedList[data.index]));
    checked.includes(newRow)
      ? setChecked((old) => old.filter((row) => row !== newRow))
      : setChecked((old) => [...old, newRow]);
  };

  return (
    <>
      <hr />
      {sortedList != "" ? (
        <div stylename="editable-list">
          {isLoaded ? (
              <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                  <Table
                    autoHeight
                    width={1200}
                    height={height ? height : 550}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    scrollTop={scrollTop}
                    headerHeight={50}
                    rowHeight={50}
                    sort={_sort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    rowCount={sortedList.length}
                    rowGetter={({ index }) => sortedList[index]}
                  >
                    <Column
                      dataKey="cnt"
                      label="NO?"
                      width={90}
                      cellRenderer={({ dataKey, rowIndex }) =>
                        _cellRenderer(dataKey, rowIndex)
                      }
                    />
                    <Column
                      dataKey="name"
                      label="NAME?"
                      width={280}
                      cellRenderer={({ dataKey, rowIndex }) =>
                        _cellRenderer(dataKey, rowIndex)
                      }
                    />
                    <Column
                      dataKey="walkin"
                      label="WALK-IN/PRE-REG?"
                      width={300}
                      className="column-centered"
                      cellRenderer={({ dataKey, rowIndex }) =>
                        _cellRendererButtonWalkIn(dataKey, rowIndex)
                      }
                    />
                    <Column
                      dataKey="completed"
                      label="COMPLETED?"
                      width={300}
                      className="column-centered"
                      cellRenderer={({ dataKey, rowIndex }) =>
                        _cellRendererButtonCompleted(dataKey, rowIndex)
                      }
                    />
                    <Column
                      dataKey="completiondate"
                      label="COMPLETION DATE?"
                      width={250}
                      className="column-centered"
                      cellRenderer={({ dataKey, rowIndex }) =>
                        _cellRendererButtonCompletionDate(dataKey, rowIndex)
                      }
                    />
                    <Column
                      dataKey="completioncertificate"
                      label="TRANSCRIPT?"
                      width={175}
                      className="column-centered"
                      cellRenderer={({ dataKey, rowIndex }) =>
                        _cellRendererButtonCertificate(dataKey, rowIndex)
                      }
                    />
                    <Column
                      dataKey="previouscompletiondates"
                      label="PREVIOUS COMPLETIONS?"
                      width={600}
                      className="column-centered"
                      cellRenderer={({ dataKey, rowIndex }) =>
                        _cellRendererButtonPreviousCompletions(dataKey, rowIndex)
                      }
                    />
                  </Table>
                )}
              </WindowScroller>
          ) : (
            <LoadSpinner />
          )}
        </div>
      ) : (
        <div className='no-enrollments-container'>No Enrollments found</div>
      )}
    </>
  );
}
