import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
/* load 'fs' for readFile and writeFile support */
//import * as fs from "fs";
import {
  Dimmer,
  Loader,
  Input,
  Grid,
  Icon,
  Button,
  Dropdown,
  Form,
  Message,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import "./../../config";
import axios from "axios";

import { courseSelect } from "../../Redux/Actions/coursesAction";
import { Modal } from "react-bootstrap";
import CourseModal from "./courseModal";

function SchooxAdhocTranscripts() {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [error, setError] = useState(null);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});
  const [show, setShow] = useState(false);
  const [searchResultCount, setSearchResultCount] = useState(null);
  const [disableSearch, setDisableSearch] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const navigate= useNavigate();
  let baseApiUrl = global.config.baseApiUrl;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { courses: currentCourses, course: currentCourse } = useSelector(
    (state) => state.courses
  );
  const { user: currentUser } = useSelector((state) => state.auth);
  //console.log("This is my currentUser from state.auth.user:", currentUser)
  const dispatch = useDispatch();

  const useHasChanged = (val) => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
  };

  const onChangeQuery = (e) => {
    const search = e.target.value;
    console.log(search);
    if (search) {
      setQuery(search);
      //requires three characters to enable Search
      if (search.length > 2) {
        setDisableSearch(false);
      } else {
        setDisableSearch(true);
      }
    } else {
      setDisableSearch(true);
      setQuery("");
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const hasCourseChanged = useHasChanged(course);

  useEffect(() => {
    //getSchooxCourses();
    setDisableConfirm(true);

    return () => {
      //unmounted = true;
    };
  }, []);

  const getSchooxCourses = async () => {
    setLoading(true);
    let baseApiUrl = global.config.baseApiUrl;
    let url = baseApiUrl + "/courses";
    console.log("getSchooxCourses: url: " + url);

    if (query) {
      try {
        let reqBody = { searchText: query, jwt: currentUser.jwt};

        let data = await axios.post(url, reqBody);

        const courses_array = data.data.courses.map(
          ({ course_id, course_name, course_level, instructor_name }) => ({
            label: course_id + " - " + course_name + " - " + course_level,
            value: course_id,
            selectedCourse: {
              course_id: JSON.stringify(course_id),
              course_name: course_name,
              course_level: course_level,
              instructor_name: instructor_name,
            },
          })
        );
        if (courses_array && courses_array.length > 0) {
          console.log("courses array: " + JSON.stringify(courses_array));

          /*const courses = data.data.courses.map(({ course_id, course_name, course_description }) => ({ key: course_id + " - " + 
                              course_name, 
                              value: course_id,
                              text: course_id + " - " + course_name 
                              }));
          console.log('The Courses are: ' + JSON.stringify(courses));*/

          setCourses(courses_array);
          setDisableConfirm(true);
          setSearchResultCount(courses_array.length);
        } else {
          setCourses([]);
          setSearchResultCount(0);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
      }
    }
  };

  const handleCourseChange = (e, { value }) => {
    console.log("selected:", value);
    console.log("handleCourseChange:courses: " + JSON.stringify(courses));
    var selectedCourse = courses.filter((course) => course.value === value);
    console.log(
      "handleCourseChange:selectedCourse: " + JSON.stringify(selectedCourse)
    );
    dispatch(courseSelect(selectedCourse[0].selectedCourse));
    console.log("course: " + JSON.stringify(selectedCourse[0]));
    setCourse(selectedCourse[0].value);
    console.log("Course Id: " + selectedCourse[0].value);
    if (course) {
      setDisableConfirm(false);
    } else {
      setDisableConfirm(true);
    }
  };

  const onCourseFormSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleSubmit = (e) => {
    console.log("Inside handleSubmit");
    e.preventDefault();    
    navigate("/manageCourseRoster", {replace:true});
  };
  const handleOfflineRoster = (e) => {
    console.log("Inside handleOfflineRoster");
    e.preventDefault();
    console.log(course);

    let companyId = global.config.companyId;
    const apiData = [
      {
        Company: companyId, //this is pulling from the url host...where's the right place to find this??
      },
    ];
    //Set filesystem
    //XLSX.set_fs(fs);

    //get course data and format for template
    let currCourse = courses.filter((crs) => crs.value == course);
    console.log(currCourse);

    //read in xlsx template file via AJAX since readFile won't work from the browser
    (async () => {
      const url = `${window.location.protocol}//${window.location.host}/AdHocOfflineTemplate.xlsx`;
      console.log(url);
      const data = await (await fetch(url)).arrayBuffer();
      /* data is an ArrayBuffer */
      const workbook = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        dateNF: "yyyy/mm/dd;@",
      });
      console.log(workbook);

      /* 
      Use retrieved Workbook to produce the new one with 
      course data applied
       */
      // get first sheet
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];

      /* 
      Copy currentCourse values into the 
      sheet's headers for download  
      */
      // OPTION 1: modify existing values in Cells(template must include dummy values)
      // worksheet["C1"].v = apiData[0].Company;
      // worksheet["C2"].v = currCourse[0].selectedCourse.course_name;
      // worksheet["C3"].v = currCourse[0].selectedCourse.course_id;
      // worksheet["C4"].v = currCourse[0].selectedCourse.course_level;
      // worksheet["C5"].v = currCourse[0].selectedCourse.instructor_name;
      // worksheet["C6"].v = currentUser.firstname + " " + currentUser.lastname;

      // OPTION 2: modify value if cell value is undefined / does not exist
      XLSX.utils.sheet_add_aoa(worksheet, [[apiData[0].Company]], {
        origin: "C1",
      });
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[currCourse[0].selectedCourse.course_name]],
        {
          origin: "C2",
        }
      );
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[currCourse[0].selectedCourse.course_id]],
        {
          origin: "C3",
        }
      );
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[currCourse[0].selectedCourse.course_level]],
        {
          origin: "C4",
        }
      );
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[currCourse[0].selectedCourse.instructor_name]],
        {
          origin: "C5",
        }
      );
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[`${currentUser.firstname} ${currentUser.lastname}`]],
        {
          origin: "C6",
        }
      );

      // write to new file
      // formatting from OLD file will be lost!
      const trimmedCourseName =
        currCourse[0].selectedCourse.course_name.replaceAll(" ", "");
      const trimmedInstructorName =
        currCourse[0].selectedCourse.instructor_name.replaceAll(" ", "");
      const newFileType = "xlsx";
      const newFileName = `${companyId}_${trimmedCourseName}_${currCourse[0].selectedCourse.course_id}_${trimmedInstructorName}.${newFileType}`;
      XLSX.writeFile(workbook, newFileName, {
        cellDates: true,
        type: "xlsx",
      });
    })();
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled ? "red" : "blue",
        color: "#FFF",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  return (
    <>
      <Form>
        <label>
          <strong>Search for an ILT Course</strong>
        </label>
        <Form.Field inline>
          <Input
            placeholder="Enter search criteria to find a course."
            onChange={onChangeQuery}
            value={query}
            style={{ width: "500px" }}
          />
          <Button
            color="blue"
            content="Search"
            onClick={getSchooxCourses}
            disabled={disableSearch}
          />{" "}
        </Form.Field>

        <Loader active={loading} inline="centered">
          Fetching courses...
        </Loader>

        {searchResultCount > 0 && (
          <>
            <label>
              {/* <strong>Select ILT Course (Course Id - Course Title)</strong> */}
              <strong>Select ILT Course</strong>
            </label>
            <Form.Field>
              <Dropdown
                placeholder="Select ILT Course"
                options={courses.map((ds) => {
                  return {
                    key: ds.value,
                    text: ds.label,
                    value: ds.value,
                  };
                })}
                selection
                onChange={handleCourseChange}
              />
            </Form.Field>
          </>
        )}
        {query && searchResultCount === 0 && 'No courses found'}
      </Form>
      <br />
      <br />
      <div className="d-flex align-items-center justify-content-center">
        <Button color="blue" onClick={handleShow} disabled={disableConfirm}>
          Confirm
        </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CourseModal onSubmit={onCourseFormSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleOfflineRoster}>
            <Icon color="black" name="download" />
            Offline Roster
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SchooxAdhocTranscripts;
