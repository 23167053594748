import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  getLMSEnrollments,
  selectUser,
  selectUsers,
} from "../../Redux/Actions/enrollmentsAction";
import { courseSelect } from "../../Redux/Actions/coursesAction";
import "bootstrap/dist/css/bootstrap.css";
import "./manageCourseRoster.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SchooxRosterTable from "../components/schooxRosterTable";
//import UserInfoModal from "../components/userInfoModal";
import UserInfoModal from "../components/usersModal";
import _ from "lodash";
import "./../../config";
import "../components/user.css";
import LoadSpinner from "../../components/LoadSpinner";
import { Dimmer, Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import moment from "react-moment";
import Spacer from "react-add-space";
import UploadRosterModal from "../components/uploadRosterModal";

const ManageCourseRoster = () => {
  const [course, setCourse] = useState({});
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [usersInfo, setUsersInfo] = useState([]);
  const [completionDate, setCompletionDate] = useState("");

  const [enrollmentsLoading, setEnrollmentsLoading] = useState(true);
  const [usersLoading, setUsersLoading] = useState(false);

  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseLevel, setCourseLevel] = useState("");
  const [showUploadRosterForm, setShowUploadRosterForm] = useState(false);
  const [showUploadResultsReport, setShowUploadErrorReport] = useState(false);
  const [rosterData, setRosterData] = useState([]);
  const [rosterUploadCompleted, setRosterUploadCompleted] = useState(false);
  const [rosterUploadResults, setRosterUploadResults] = useState([]);
  const [displayRosterData, setDisplayRosterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate= useNavigate();

  //let baseApiUrl = "http://rldschoox.localhost:8080/v1";
  let baseApiUrl = global.config.baseApiUrl;

  const { course: currentCourse } = useSelector((state) => state.courses);
  const { user: currentUser } = useSelector((state) => state.auth);
  const {
    user: selectedUser,
    users: selectedUsers,
    enrollments: currentEnrollments,
  } = useSelector((state) => state.enrollments);

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    console.log("Clicked handleClose()********");
    setDisabled(true);
    dispatch(selectUser({}));
    setName("");
    setUsername("");
    setShow(false);
  };

  const handleShowUploadRoster = (e) => {
    console.log("handleShowUploadRoster");
    //allow user to select xlsx file from computer
    setRosterData([]);
    setShowUploadRosterForm(true);
  };

  const handleShowUploadErrorReport = (e) => {
    console.log("handleShowUploadErrorReport");
    setShowUploadErrorReport(true);
  };
  const handleCloseUploadResultsReport = (e) => {
    setShowUploadErrorReport(false);
  };
  const handleCloseUploadRoster = (e) => {
    setShowUploadRosterForm(false);
  };

  const handleImportRoster = async (e) => {
    console.log("handleImportRoster");
    console.log(rosterData);
    //import the roster data
    setLoading(true);
    //TODO: use the new JSON data when user clicks "Accept and Import"
    const apiRosterData = {
      courseId: courseId,
      learners: [...rosterData],
      instructorName: currentCourse.instructor_name,
      jwt: currentUser.jwt,
      instructorId: String(currentUser.id),
    };
    console.log(apiRosterData);
    let baseApiUrl = global.config.baseApiUrl;
    let url = baseApiUrl + "/learner/bulk/complete";
    console.log("bulkLearnerCompletions url: " + url);

    if (apiRosterData) {
      try {
        let reqBody = apiRosterData;

        let data = await axios.post(url, reqBody);
        console.log("data:", data);
        const importResults = data.data.data_import_result;
        // const importResults = data.data.data_import_result.filter(
        //   (item) => item.status === "fail"
        // );
        if (importResults && importResults.length > 0) {
          const importedItems = importResults.map((item) => {
            return {
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              message:
                item.status === "fail" ? item.message : "Successfully imported",
              completionDate: item.completionDate,
            };
          });
          console.log("importResults:", importedItems);
          setRosterUploadResults([...importedItems]);
          //due to state update scheduling, can't rely on immediately
          //reading newly updated rosterUploadResults,
          //so we offload the display call to a useEffect
          //with dependency on rosterUploadResults (below)
        }
        //set to show report button
        setRosterUploadCompleted(true);
        if (data.data.enrollments) {
          dispatch(getLMSEnrollments(data.data.enrollments, false));
        }
        // setCurrentEnrollments([...data.data.enrollments]);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    //clear the state
    setRosterData([]);
    //close the modal
    setShowUploadRosterForm(false);
  };

  const formatUploadErrorReport = () => {
    console.log("formatUploadErrorReport", rosterUploadResults);
    //setup the error report display
    const listItems = rosterUploadResults.map((user) => (
      <tr key={user.id}>
        <td>{user.id}</td>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.completionDate}</td>
        <td>{user.message}</td>
      </tr>
    ));
    console.log(listItems);
    //this will be used for preview display only
    setDisplayRosterData([...listItems]);
    console.log(displayRosterData);
  };

  useEffect(() => {
    //once the state is updated, load the display
    formatUploadErrorReport();
  }, [rosterUploadResults]);

  useEffect(() => {
    console.log("currentCourse", currentCourse)
    if (currentCourse) {
      setCourseId(currentCourse.course_id);
      setCourseName(currentCourse.course_name);
      setCourseLevel(currentCourse.course_level);
    }
  }, [currentCourse]);

  useEffect(() => {
    if (selectedUser) {
      setDisabled(false);
    }
  }, [selectedUser]);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    console.log("username: " + JSON.stringify(username));
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    console.log("name: " + JSON.stringify(name));
  };

  const [isSending, setIsSending] = useState(false);
  const isMounted = useRef(true);

  const handleBack = (e) => {
    console.log("Inside handleBack");
    e.preventDefault();    
    navigate("/selectRoster", { replace: true });
  };

  // set isMounted to false when we unmount the component
  //useEffect(() => {
  //  return () => {
  //    isMounted.current = false
  //  }
  //}, [])

  const handleShow = () => {
    dispatch(selectUser([]));
    setUsersInfo([]);
    // send the actual request
    let url = baseApiUrl + "/people";
    console.log("getLMSUserInfo: url: " + url);

    setUsersLoading(true);
    if (username == null || username === "") {
      setUserInfo({});
      setUsersLoading(false);
      setShow(true);
    } else {
      let reqBody = { userId: username, accessToken: currentUser.accessToken, jwt: currentUser.jwt };
      console.log("The UserId is: " + JSON.stringify(username));

      axios.post(url, reqBody).then(
        (response) => {
          console.log("course handleShow: " + response);
          //dispatch(selectUser(username));
          console.log(
            "user info is: " + JSON.stringify(response.data.userInfo)
          );
          console.log("users is: " + JSON.stringify(response.data.users));

          if (response.data.userInfo) {
            setUsersInfo(response.data.userInfo);
            dispatch(selectUser(response.data.userInfo));
          } else if (response.data.users) {
            setUsersInfo(response.data.users);
            dispatch(selectUser(response.data.users));
          } else {
            setUsersInfo([]);
          }
          setDisabled(true);
          setUsersLoading(false);
          setShow(true);
        },
        (error) => {
          dispatch(selectUsers([]));
          setDisabled(true);
          setUsersInfo([]);
          setUsersLoading(false);
          console.log(error);
        }
      );
    }
  }; // update the callback if the state changes
  const handleKeypress = (e) => {
    console.log("handleKeypress", e);
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      console.log("enter");
      handleSearchUsers();
    }
  };

  const handleSearchUsers = () => {
    // send the actual request
    let url = baseApiUrl + "/people";
    console.log("getLMSUserInfo: url: " + url);

    setUsersLoading(true);
    if (name == null || name === "") {
      setUsersInfo([]);
      setUsersLoading(false);
      setShow(true);
    } else {
      let reqBody = { search_text: name, accessToken: currentUser.accessToken, jwt: currentUser.jwt };
      console.log("The search_text is: " + JSON.stringify(name));

      axios.post(url, reqBody).then(
        (response) => {
          console.log(
            "People Search Response:" + JSON.stringify(response.data.users)
          );
          //dispatch(selectUser(response.data.users));
          dispatch(selectUsers(response.data.users));
          setDisabled(true);
          setUsersInfo(response.data.users);
          console.log("users info is: " + JSON.stringify(response.data.users));

          setUsersLoading(false);
          setShow(true);
        },
        (error) => {
          dispatch(selectUsers([]));
          setDisabled(true);
          setUsersInfo([]);
          setUsersLoading(false);
          console.log(error);
        }
      );
    }
  }; // update the callback if the state changes

  const onUserInfoFormSubmit = (e) => {
    e.preventDefault();
    handleClose();
    handleSubmit();
  };

  const convertDate = (date) => {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split("");
    var ddChars = dd.split("");

    var cmplDt = yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0])
    console.log("DATE VALUE TO BE SET IN markComplete: Inside handleSubmit: " + JSON.stringify(cmplDt));

    return (
      yyyy +
      "-" +
      (mmChars[1] ? mm : "0" + mmChars[0]) +
      "-" +
      (ddChars[1] ? dd : "0" + ddChars[0])
    );
  };

  async function handleSubmit(e) {
    handleClose();

    setEnrollmentsLoading(true);
    console.log("Inside handleSubmit: " + JSON.stringify(selectedUser));
    // Open a New Model and capture Completion Date
    // Click Submit to mark Learner complete
    // Load Enrollment Listings

    var failed = false;
    //e.preventDefault();
    // Set the UserId in the Redux
    // Check-In Learner | Mark Attendance | Completion | Walk-In Learner
    // Check Learner if in DB, also check the enrollment listings
    // Walk-In,
    // Check-In
    // Mark Attendance
    // Mark Completion

    // send the actual request
    let url = baseApiUrl + "/learner/complete";
    console.log("getLMSLearnerCheck: url: " + url);

    console.log("Current LoggedIn User is: " + JSON.stringify(currentUser));

    if (!username) {
      setUsername(selectedUser);
    }

    let course_id = currentCourse.course_id;
    setCourseId(course_id);
    setCourseName(currentCourse.course_name);
    setCompletionDate(convertDate(new Date()));

    let reqBody = {
      courseId: String(currentCourse.course_id),
      accessToken: currentUser.accessToken,
      instructorId: String(currentUser.id),
      instructorName: currentUser.firstname + " " + currentUser.lastname,
      completionDate: convertDate(new Date()),
      jwt: currentUser.jwt,
      learners: [selectedUser],
    };

    console.log("The reqBody is: " + JSON.stringify(reqBody));
    let data = await axios.post(url, reqBody);

    if (data) {
      dispatch(getLMSEnrollments(data, failed));
    }

    setEnrollmentsLoading(false);
  }

  // Get Enrollment Listings
  useEffect(() => {
    //let unmounted = false;
    //setCourse(currentCourse);
    console.log("Current LoggedIn User is: " + JSON.stringify(currentUser));
    console.log("Current LoggedIn Course is: " + JSON.stringify(currentCourse));
    getCourseEnrollmentListings();

    return () => {
      //unmounted = true;
    };
  }, []);

  async function getCourseEnrollmentListings() {
    let unmounted = false;
    var failed = false;
    setEnrollmentsLoading(true);

    let url = baseApiUrl + "/enrollments";
    console.log("getCourseEnrollmentListings: url: " + url);

    let course_id = "";
    let course_name = "";
    let course_level = "";

    console.log("getEnrollmentListings based on courseId: " + courseId);
    let reqBody = { courseId: currentCourse.course_id, jwt: currentUser.jwt };
    let data = await axios.post(url, reqBody);

    console.log("The Enrollments are: " + JSON.stringify(data));
    if (data) {
      dispatch(getLMSEnrollments(data, failed));
    }

    if (!unmounted) {
      setEnrollmentsLoading(false);
    }
  }

  return (
    <Container>
      <h1>Manage Roster</h1>
      <hr />
      <Row>
        <Col sm={6}>
          <Button variant="secondary" onClick={handleBack}>
            Go Back
          </Button>
        </Col>
        <Col sm={6} className="right-align">
          <Button variant="primary" onClick={handleShowUploadRoster}>
            Upload Offline Roster
          </Button>
          {rosterUploadCompleted && (
            <Button
              className="ml-2"
              variant="warning"
              onClick={handleShowUploadErrorReport}
            >
              Import Results
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12}>
          <InputGroup className="mb-1">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <b>Course No</b>
              </InputGroup.Text>
              <InputGroup.Text>{courseId}</InputGroup.Text>
            </InputGroup.Prepend>
            <Spacer amount={8} />
            <InputGroup.Prepend>
              <InputGroup.Text>
                <b>Course Name</b>
              </InputGroup.Text>
              <InputGroup.Text>{courseName}</InputGroup.Text>
            </InputGroup.Prepend>
            <Spacer amount={8} />
            <InputGroup.Prepend>
              <InputGroup.Text>
                <b>Course Level</b>
              </InputGroup.Text>
              <InputGroup.Text>{courseLevel}</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>First and/or last name</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="First and/or last name"
              onChange={onChangeName}
              value={name}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearchUsers();
                }
              }}
            />
            <InputGroup.Append>
              <Button variant="primary" onClick={handleSearchUsers}>
                Find Learner
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Form.Label>
            <b>OR</b>
          </Form.Label>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12}>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Employee ID or Username or Badge ID"
              aria-label="Employee ID or Username or Badge ID"
              aria-describedby="basic-addon2"
              onChange={onChangeUsername}
              value={username}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleShow();
                }
              }}
            />
            <InputGroup.Append>
              <Button variant="primary" onClick={handleShow}>
                Search
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12}>
          <Dimmer active={usersLoading}>
            <Loader>Fetching users...</Loader>
          </Dimmer>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12}>
          {enrollmentsLoading ? <LoadSpinner /> : <SchooxRosterTable />}
        </Col>
      </Row>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="user-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm User Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {usersInfo === [] ? (
            <UserInfoModal
              onSubmit={onUserInfoFormSubmit}
              userInfo={userInfo}
            />
          ) : (
            <UserInfoModal
              onSubmit={onUserInfoFormSubmit}
              userInfo={usersInfo}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="secondary"
            disabled={disabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUploadRosterForm}
        onHide={handleCloseUploadRoster}
        dialogClassName="upload-roster-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Offline Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadRosterModal
            rosterData={rosterData}
            setRosterData={setRosterData}
            course={currentCourse}
            enrollments={currentEnrollments}
          />
          <Loader active={loading} inline="centered">
            Importing roster data...
          </Loader>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseUploadRoster}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            disabled={!rosterData || rosterData.length === 0 || loading}
            onClick={handleImportRoster}
          >
            Accept and Import
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size={"lg"}
        show={showUploadResultsReport}
        onHide={handleCloseUploadResultsReport}
        dialogClassName="upload-results-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scrolling-container">
            {rosterUploadResults && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Completed On</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>{displayRosterData}</tbody>
              </Table>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUploadResultsReport}>
            Close
          </Button>
          {/* <Button
            variant="secondary"
            disabled={!rosterData || rosterData.length === 0}
            onClick={handleImportRoster}
          >
            Accept and Import
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ManageCourseRoster;
